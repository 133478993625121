import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'

import veribilimi101old from '../assets/images/courses/veri-bilimi-101-old.png'
// import veribilimi101 from '../assets/images/courses/veri-bilimi-101.png'
import frontend101 from '../assets/images/courses/front-end-101.png'
import content from '../Courses/veribilimi101.json'


const Courses = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    // JSON dosyasını kullanarak veriyi set edin
    setData(content);
  }, []);

  if (!data) {
    return <div>Loading...</div>;
  }
  return (
    <div>
    <h1 className="text-seoem-100 text-2xl text-center font-bold mb-4">Eğitimler</h1>
      
      <div className='flex flex-col w-4/5 mx-auto items-center'>
      <div className='course'>
          <div className='relative'>
            <img className='w-[400px] rounded-lg' src={frontend101} alt='veribilimi101 görsel'/>
            <span className='bg-red-700 text-white text-center p-1 rounded-md absolute top-2 left-2'>7 Aralık</span>
          </div>
          <div className='course-box'>
            <h2 className='course-title'>Front-End Geliştirmeye Giriş Eğitimi</h2>
            <p className='course-date'>Başlangıç Tarihi: 07.12.2024</p>
            <p className='course-description'>SEOEM Akademi ile Front-End geliştirme macerasına siz de katılın!</p>
            <p className='course-instructor'>SEOEM Akademi</p>

            <ul className='max-lg:flex max-lg:flex-col mt-4'>
              <Link className='btn btn-light' to={'/frontend'}>Detayları Öğren ve Kaydol</Link>
            </ul>
          </div>
        </div>
      {/* <div className='course'>
          <div className='relative'>
            <img className='w-[400px] rounded-lg' src={veribilimi101} alt='veribilimi101 görsel'/>
            <span className='bg-red-700 text-white text-center p-1 rounded-md absolute top-2 left-2'>{data.datewtext}</span>
          </div>
          <div className='course-box'>
            <h2 className='course-title'>{data.title}</h2>
            <p className='course-date'>Başlangıç Tarihi: {data.date}</p>
            <p className='course-description'>{data.shortDescription}</p>
            <p className='course-instructor'>{data.instructor}</p>

            <ul className='max-lg:flex max-lg:flex-col mt-4'>
              <Link className='btn btn-disabled' to={'/'}>Bitti</Link>
            </ul>
          </div>
        </div>
        <div className='course'>
          <div className='relative'>
            <img className='w-[400px] rounded-lg' src={frontend101} alt='veribilimi101 görsel'/>
            <span className='bg-red-700 text-white text-center p-1 rounded-md absolute top-2 left-2'>2 Kasım</span>
          </div>
          <div className='course-box'>
            <h2 className='course-title'>Front-End Geliştirmeye Giriş Eğitimi</h2>
            <p className='course-date'>Başlangıç Tarihi: 02.11.2024</p>
            <p className='course-description'>SEOEM Akademi ile Front-End geliştirme macerasına siz de katılın!</p>
            <p className='course-instructor'>SEOEM Akademi</p>

            <ul className='max-lg:flex max-lg:flex-col mt-4'>
            <Link className='btn btn-disabled' to={'/'}>Bitti</Link>
            </ul>
          </div>
        </div> */}

        <div className='course'>
          <div className='relative'>
            <img className='w-[400px] rounded-lg' src={veribilimi101old} alt='veribilimi101 görsel'/>
            <span className='course-image-span'>Geçmiş Etkinlik</span>
          </div>
          <div className='course-box'>
            <h2 className='course-title'>Veri Bilimi Bootcamp</h2>
            <p className='course-date'>Başlangıç Tarihi: 19.03.2024</p>
            <p className='course-description'>SEOEM Akademi ile veri bilimi ve makine öğreniminin heyecanlı dünyasına giriş yapın.</p>
            <p className='course-instructor'>SEOEM Akademi</p>

            <ul className='max-lg:flex max-lg:flex-col mt-4'>
              <Link className='btn btn-disabled' to={'/'}>Bitti</Link>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Courses;